import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import s from "./StackCarousel.module.scss";
import TextAndImage from "../../../components/Nestable/TextAndImage";
import TextAndVideo from "../../../components/Nestable/TextAndVideo";
import BlockIntro from "../../Common/BlockIntro";
import bs from "../../../styles/block.spacing.module.scss";

interface ScrolledItem {
  index: number;
  scale: number;
  topOffset: number;
  isUnder: boolean;
}

const StackCarousel = ({
  stackingItems,
  title,
  description,
  buttons,
  inverted,
  layout,
  spacing,
  outerSpacing,
}) => {
  const [scrolledItems, setScrolledItems] = useState<ScrolledItem[]>([]);
  const [containerStartScroll, setContainerStartScroll] = useState<
    number | null
  >(null);
  const [activeVideoIndex, setActiveVideoIndex] = useState<number | null>(null);
  const [isGroupMoving, setIsGroupMoving] = useState(false);
  const [groupTransform, setGroupTransform] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const triggerScrollPosition = useRef(0);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    itemsRef.current = Array(stackingItems?.length || 0).fill(null);
  }, [stackingItems]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (!container) return;

      const currentScroll = window.scrollY;
      const containerRect = container.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (containerRect.top < viewportHeight && containerStartScroll === null) {
        setContainerStartScroll(currentScroll);
      }

      const items = itemsRef.current.filter(Boolean);
      if (items.length === 0) return;

      const lastItemIndex = items.length - 1;
      const lastItem = items[lastItemIndex];
      if (!lastItem) return;

      const lastItemRect = lastItem.getBoundingClientRect();
      const firstItemRect = items[0]?.getBoundingClientRect();

      if (!firstItemRect) {
        return;
      }

      const stackedPosition = 110;
      const lastItemHasReachedFinalPosition =
        lastItemRect.top <= stackedPosition;

      if (lastItemHasReachedFinalPosition) {
        if (!isGroupMoving) {
          setIsGroupMoving(true);
          triggerScrollPosition.current = currentScroll;
        }

        const scrollOffset = currentScroll - triggerScrollPosition.current;
        setGroupTransform(scrollOffset);
      } else {
        if (isGroupMoving) {
          setIsGroupMoving(false);
          setGroupTransform(0);
        }

        let progress = 0;

        if (containerStartScroll !== null) {
          const totalHeight = firstItemRect.height * items.length;
          progress =
            ((currentScroll - containerStartScroll) * 100) / totalHeight;
        }

        const newScrolledItems = items.map((item, index) => {
          const spacing = 30;
          let scale = 1;
          const topOffset = index * spacing;
          let isUnder = false;

          if (index < items.length - 1) {
            const nextItem = items[index + 1];
            if (nextItem) {
              const itemRect = item?.getBoundingClientRect();
              const nextRect = nextItem.getBoundingClientRect();

              if (itemRect && nextRect.top < itemRect.height + 80) {
                isUnder = true;
              }

              if (isUnder && containerRect.top < 0) {
                scale = 1 - ((progress - (index - 1) * 30) / 100) * 0.1;
              }
            }
          }

          return {
            index,
            scale,
            topOffset,
            isUnder,
          };
        });

        setScrolledItems(newScrolledItems);

        const activeSlideIndex = newScrolledItems.findIndex(
          (item) => !item.isUnder
        );
        if (activeSlideIndex !== -1 && activeSlideIndex !== activeVideoIndex) {
          setActiveVideoIndex(activeSlideIndex);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [containerStartScroll, activeVideoIndex, isGroupMoving]);

  return (
    <>
      <div className={cn(bs[spacing], bs[outerSpacing])}>
        <BlockIntro
          alignment={"alignCenter"}
          inverted={inverted}
          title={title}
          description={description}
          buttons={buttons}
          width={"contained"}
        />
        <div
          ref={containerRef}
          className={cn(s.scrollTriggered, s.noGapForStackCarousel, {
            [s.groupMoving]: isGroupMoving,
          })}
          style={
            isGroupMoving
              ? { transform: `translateY(-${groupTransform}px)` }
              : {}
          }
        >
          {stackingItems &&
            stackingItems.map((item, index) => {
              const scale = scrolledItems[index]?.scale || 1;
              const isSticky =
                index === 0 ? { position: "sticky" as const, top: 0 } : {};
              const topOffset = scrolledItems[index]?.topOffset || 0;

              const setRef = (el: HTMLDivElement | null) => {
                itemsRef.current[index] = el;
              };

              if (item.component === "textAndImage") {
                return (
                  <div
                    id={`block${index}`}
                    ref={setRef}
                    className={cn(s.stackItem)}
                    key={item._uid}
                    style={{
                      transform: `scale(${scale})`,
                      top: `${topOffset}px`,
                      ...isSticky,
                    }}
                  >
                    <TextAndImage
                      className={cn(s.noGapStackCarousel)}
                      _uid={item._uid}
                      component={item.component}
                      _editable={item._editable}
                      text={item.text}
                      image={item.image}
                      title={item.title}
                      reversed={true}
                      buttonText={item.buttonText}
                      buttonLink={item.buttonLink}
                      secondButtonText={item.secondButtonText}
                      secondButtonLink={item.secondButtonLink}
                      style={
                        item.style === "none" ||
                        item.style === "white" ||
                        item.style === "gray"
                          ? "frostBlue"
                          : item.style
                      }
                      mediaBackground={item.mediaBackground}
                      layout={layout}
                      typeformSize={100}
                      typeformButtonStyle={item.typeformButtonStyle}
                      imageKeepProportions={false}
                      typeformCopy={item.typeformCopy}
                      typeformID={item.typeformID}
                      outerSpacing={"outerNone"}
                    />
                  </div>
                );
              } else if (item.component === "textAndVideo") {
                const isActiveVideo = index === activeVideoIndex;
                return (
                  <div
                    id={`block${index}`}
                    ref={setRef}
                    className={cn(s.stackItem)}
                    key={item._uid}
                    style={{
                      transform: `scale(${scale})`,
                      top: `${topOffset}px`,
                      ...isSticky,
                    }}
                  >
                    <TextAndVideo
                      className={cn(s.noGap, s.noGapStackCarousel)}
                      key={item._uid}
                      _uid={item._uid}
                      component={item.component}
                      _editable={item._editable}
                      text={item.text}
                      title={item.title}
                      assetVideo={item.assetVideo}
                      reversed={true}
                      buttonText={item.buttonText}
                      buttonLink={item.buttonLink}
                      secondButtonText={item.secondButtonText}
                      secondButtonLink={item.secondButtonLink}
                      style={item.style}
                      mediaBackground={item.mediaBackground}
                      layout={layout}
                      typeformSize={100}
                      typeformButtonStyle={"default"}
                      imageKeepProportions={false}
                      typeformCopy={""}
                      typeformID={""}
                      outerSpacing={"outerNone"}
                      dark={false}
                      thumbnailImage={item.thumbnailImage}
                      video={[]}
                      isActive={isActiveVideo}
                    />
                  </div>
                );
              }

              return null;
            })}
        </div>
      </div>
    </>
  );
};

export default StackCarousel;
