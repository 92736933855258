import React, { ReactNode } from "react";
import cn from "classnames";
import dynamic from "next/dynamic";
import s from "./MediaAndImage.module.scss";
import b from "./../Button/Button.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import c from "../../styles/container.module.scss";
import s2 from "../Editorial/components/EditorialCopy.module.scss";
import { useTranslation } from "next-i18next";
import {
  OuterSpacingVariant,
  StyleVariant,
  ButtonStyleVariant,
  PercentageVariant,
} from "../../lib/storyblok/types/shared";
import BlockIntro from "../Common/BlockIntro";
import BlockBackground from "../Common/BlockBackground";
import VideoPlayer from "../Common/VideoPlayer";
import { isValidImageData } from "../../lib/utils/imageUtils";
import ImageVaultImage from "../../components/ImageVaultImage";
import videoThumbnailFallback from "../../public/images/chapter-divider-01.png";
import Image from "next/image";
import {
  ImageVaultFieldType,
  StoryblokAsset,
} from "../../lib/storyblok/types/fieldtypes/imagevaultFieldtype";

// Dynamic import for Typeform PopupButton
const PopupButton = dynamic(
  () => import("@typeform/embed-react").then((mod) => mod.PopupButton),
  { ssr: false }
);

type Props = {
  title: string;
  preamble: string;
  button?: ReactNode;
  secondButton?: ReactNode;
  typeformCopy: string;
  typeformID: string;
  typeformSize: PercentageVariant;
  typeformButtonStyle: ButtonStyleVariant;
  image?: ReactNode;
  video?: ReactNode;
  assetVideo?: string | { filename: string; alt?: string; copyright?: string };
  reversed?: boolean;
  dark?: boolean;
  author?: string;
  publishedAt?: string;
  readTime?: string;
  imageKeepProportions: boolean;
  style: StyleVariant;
  outerSpacing: OuterSpacingVariant;
  className?: string;
  mediaBackground:
    | "mediaBgWhite"
    | "mediaBgGray"
    | "mediaBgSignature"
    | "mediaBgBlack"
    | "mediaBgLitterbox"
    | "mediaBgTransparent";
  layout?: "layout11" | "layout12" | "layout21";
  inverted?: boolean;
  overlayVisible?: boolean;
  isActive?: boolean;
  thumbnailImage?: ImageVaultFieldType & Partial<StoryblokAsset>;
};

const MediaAndImage = ({
  title,
  preamble,
  className,
  button,
  secondButton,
  typeformCopy,
  typeformID,
  typeformSize,
  typeformButtonStyle,
  image,
  video,
  assetVideo,
  reversed,
  style,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
  inverted,
  overlayVisible,
  isActive = true,
  thumbnailImage,
}: Props) => {
  const normalizedLayout = (() => {
    const baseLayout = layout || "layout11";

    return baseLayout
      .replace("layout1g1", "layout11")
      .replace("layout2g1", "layout21")
      .replace("layout1g2", "layout12");
  })();
  return (
    <>
      <section
        className={cn(
          s.textImage,
          c.xWide,
          s[style],
          bs[outerSpacing],
          s[normalizedLayout],
          className,

          {
            [s.reversed]: reversed,
            [s[style]]: style,
          }
        )}
      >
        {overlayVisible && (
          <div
            className={cn(s.overlay, { [s.overlayVisible]: overlayVisible })}
          ></div>
        )}

        <div
          className={cn(s.content, {
            [s.reversed]: reversed,
          })}
        >
          <BlockBackground style={style} opacity={100} />
          <BlockIntro
            title={title}
            description={preamble}
            alignment={"alignLeft"}
            width={"full"}
            inverted={inverted}
            descriptionStyle="faded"
            gap={"large"}
          />
          {(typeformID && typeformCopy) || button || secondButton ? (
            <div className={s.buttons}>
              {typeformID && typeformCopy && (
                <PopupButton
                  id={typeformID}
                  size={typeformSize}
                  className={cn(b.button, b[typeformButtonStyle])}
                >
                  {typeformCopy}
                </PopupButton>
              )}
              {(button || secondButton) && (
                <>
                  {button && button}
                  {secondButton && secondButton}
                </>
              )}
            </div>
          ) : null}
        </div>
        {image && (
          <div
            className={cn(s.imageContainer, s.visual, {
              [s[style]]: style,
              [s.imageKeepProportions]: imageKeepProportions,
            })}
          >
            <div
              className={cn({
                [s[mediaBackground]]: mediaBackground,
              })}
            >
              {image}
            </div>
          </div>
        )}

        {(video || assetVideo) && (
          <div className={cn(s.videoContainer, s.visual)}>
            {video && React.Children.count(video) > 0 && video}
            {assetVideo &&
              typeof assetVideo !== "string" &&
              assetVideo.filename && (
                <div className={cn(s.videoContainerAsset)}>
                  <VideoPlayer
                    key="video"
                    videoUrl={assetVideo.filename}
                    maxPlays={2}
                    isActive={isActive}
                    thumbnail={
                      thumbnailImage && isValidImageData(thumbnailImage) ? (
                        <ImageVaultImage
                          image={thumbnailImage}
                          layout="fill"
                          sizes="100vw"
                          objectFit="cover"
                          priority
                        />
                      ) : (
                        <Image
                          src={videoThumbnailFallback}
                          layout="fill"
                          objectFit="cover"
                          alt="Fallback Video Thumbnail"
                        />
                      )
                    }
                    thumbnailMode="clickable"
                  />
                </div>
              )}
          </div>
        )}
      </section>
    </>
  );
};

export const MediaAndImageSmall = ({
  title,
  preamble,
  image,
  author,
  readTime,
  reversed,
}: Props) => {
  const { t } = useTranslation("articlepage");
  return (
    <section
      className={cn(s.smallSection, {
        [s.reversed]: reversed,
      })}
    >
      <div
        className={cn(s.content, s2.copy, s.smallContent, {
          [s.reversed]: reversed,
        })}
      >
        {title && <h2 className={s.title}>{title}</h2>}
        {preamble && <p className={s.textPreamble}>{preamble}</p>}

        <div className={s.details}>
          <p>
            <label className={cn("author", s.detailsItem)}>
              {`${author} `}
            </label>
          </p>
          <p className={cn("date", s.detailsItem)}>
            {readTime && `${readTime}`} {t("minutesShort")}
          </p>
        </div>
      </div>
      {image && <div className={s.imageContainerSmall}>{image}</div>}
    </section>
  );
};

export default MediaAndImage;
