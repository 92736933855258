import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./ContentGridSlider.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import Item from "./components/Item";
import Navigation from "./components/Navigation";
import BlockIntro from "../Common/BlockIntro";
import { InnerSpacingVariant } from "../../lib/storyblok/types/shared";

type Props = {
  title: string;
  description?: string;
  spacing?: InnerSpacingVariant;
  isStartpage?: boolean;
  children: ReactNode;
};

const subComponents = { Item, Navigation };
type SubComponents = typeof subComponents;

const ContentGridSlider: CWS<Props, SubComponents> = ({
  title,
  description,
  spacing = "both",
  children,
}) => {
  return (
    <section className={cn(s.section, bs[spacing])}>
      <div className={cn(c.xWide, s.intro)}>
        <BlockIntro
          alignment={"alignLeft"}
          inverted={false}
          title={title}
          description={description}
          buttons={""}
          width={"contained"}
        />
      </div>
      <div className={cn(c.xWide, s.contentGrid)}>{children}</div>
    </section>
  );
};

ContentGridSlider.Item = Item;
ContentGridSlider.Navigation = Navigation;

export default ContentGridSlider;
