import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { useInView } from "react-intersection-observer";
import Image from "next/image";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  InteractiveGridBlock,
  InteractiveGridItemBlock,
} from "../../lib/storyblok/types/blocks/InteractiveGridBlock";
import Button from "../../ui/Button";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import InteractiveGridUi from "../../ui/InteractiveGrid";
import useMediaQuery from "../../hooks/useMatchMedia";
import { useDeviceDetect } from "../../hooks/useTouchDevice";
import ImageVaultImage from "../ImageVaultImage";
import s from "../../ui/InteractiveGrid/InteractiveGrid.module.scss";
import { useContentStyle } from "../../hooks/useStyle";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import videoThumbnailFallback from "../../public/images/chapter-divider-02.png";
import VideoPlayer from "../../ui/Common/VideoPlayer";

const InteractiveGrid: StoryblokBlockComponent<InteractiveGridBlock> = ({
  title,
  description,
  buttons,
  innerSpacing,
  outerSpacing,
  style,
  blockStyle,
  gridDesktopColumnCount,
  items,
  autoActivateFirstItem,
}) => {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const autoActivateTimerRef = useRef<NodeJS.Timeout | null>(null);

  const isIntroInverted = useContentStyle(style);
  const isBlockInverted = useContentStyle(blockStyle);
  const resolveLink = useLinks();
  const { isTouchDevice } = useDeviceDetect();

  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [gridIsVisible, setGridIsVisible] = useState(false);

  const isMobile = useMediaQuery("(max-width: 480px)");
  const isTablet = useMediaQuery("(min-width: 481px) and (max-width:900px)");
  const isLaptop = useMediaQuery("(min-width: 901px) and (max-width:1200px)");

  useEffect(() => {
    return () => {
      if (autoActivateTimerRef.current) {
        clearTimeout(autoActivateTimerRef.current);
        autoActivateTimerRef.current = null;
      }
    };
  }, []);

  const itemsPerRow = useMemo(() => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    switch (Number(gridDesktopColumnCount)) {
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        if (isLaptop) return 3;
        return 4;
      default:
        if (isLaptop) return 3;
        return 4;
    }
  }, [isMobile, isTablet, isLaptop, gridDesktopColumnCount]);

  const gridRows = useMemo(() => {
    if (isTouchDevice) return null;

    const rows: InteractiveGridItemBlock[][] = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }
    return rows;
  }, [items, itemsPerRow, isTouchDevice]);

  const { ref: observerRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const setRefs = useCallback(
    (node) => {
      gridRef.current = node;
      if (typeof observerRef === "function") {
        observerRef(node);
      }
    },
    [observerRef]
  );

  useEffect(() => {
    setGridIsVisible(inView);
  }, [inView]);

  useEffect(() => {
    if (
      gridIsVisible &&
      !isTouchDevice &&
      autoActivateFirstItem &&
      items.length > 0 &&
      !userHasInteracted &&
      !hoveredItemId
    ) {
      if (autoActivateTimerRef.current) {
        clearTimeout(autoActivateTimerRef.current);
      }

      autoActivateTimerRef.current = setTimeout(() => {
        if (!userHasInteracted && !hoveredItemId && items[0]?._uid) {
          setHoveredItemId(items[0]._uid);
        }
      }, 600);
    }

    return () => {
      if (autoActivateTimerRef.current) {
        clearTimeout(autoActivateTimerRef.current);
        autoActivateTimerRef.current = null;
      }
    };
  }, [
    gridIsVisible,
    isTouchDevice,
    autoActivateFirstItem,
    items,
    userHasInteracted,
    hoveredItemId,
  ]);

  const handleManualHoverChange = useCallback(
    (itemId: string, isHovered: boolean) => {
      setUserHasInteracted(true);

      if (autoActivateTimerRef.current) {
        clearTimeout(autoActivateTimerRef.current);
        autoActivateTimerRef.current = null;
      }

      setHoveredItemId(isHovered ? itemId : null);
    },
    []
  );

  const handleGridMouseLeave = useCallback(() => {
    setHoveredItemId(null);
  }, []);

  const renderButtons = useCallback(
    () =>
      buttons?.map((button) => (
        <Link
          key={button._uid}
          href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
          passHref
        >
          <Button
            variant={button.buttonType ?? "default"}
            href={resolveLink(button.buttonLink)}
          >
            {button.buttonText}
          </Button>
        </Link>
      )),
    [buttons, resolveLink]
  );

  return (
    <InteractiveGridUi
      title={title}
      description={description}
      buttons={renderButtons()}
      style={style}
      blockStyle={blockStyle}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      inverted={isIntroInverted}
    >
      {isTouchDevice ? (
        <div className={s.mobileGrid} data-columns={itemsPerRow}>
          {items.map((item, index) => (
            <InteractiveGridUi.ItemTouch
              key={item._uid}
              index={index}
              style={blockStyle}
              title={item.title}
              description={item.description}
              link={resolveLink(item.link)}
              image={
                item.image && isValidImageData(item.image) ? (
                  <ImageVaultImage
                    image={item.image}
                    layout="fill"
                    sizes={imageSizes("720px")}
                    objectFit="cover"
                    priority
                    alt={item.title}
                  />
                ) : null
              }
              inverted={isBlockInverted}
            />
          ))}
        </div>
      ) : (
        <div
          ref={setRefs}
          className={s.desktopGrid}
          data-items-per-row={itemsPerRow}
          onMouseLeave={handleGridMouseLeave}
        >
          {gridRows?.map((row, rowIndex) => (
            <div key={rowIndex} className={s.row}>
              {row.map((item, columnIndex) => (
                <InteractiveGridUi.ItemNonTouch
                  key={`${item._uid}-${itemsPerRow}`}
                  style={blockStyle}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  link={resolveLink(item.link)}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                  isHovered={hoveredItemId === item._uid}
                  hasHoveredItemInRow={
                    hoveredItemId !== null &&
                    row.some((rowItem) => rowItem._uid === hoveredItemId)
                  }
                  onHoverChange={(isHovered) => {
                    handleManualHoverChange(item._uid, isHovered);
                  }}
                  image={
                    item.image && isValidImageData(item.image) ? (
                      <ImageVaultImage
                        image={item.image}
                        layout="fill"
                        sizes="720px"
                        objectFit="cover"
                        priority
                        alt={item.title}
                      />
                    ) : null
                  }
                  video={
                    item.video?.filename && (
                      <VideoPlayer
                        key="video"
                        videoUrl={item.video?.filename}
                        thumbnail={
                          item.image && isValidImageData(item.image) ? (
                            <ImageVaultImage
                              image={item.image}
                              layout="fill"
                              sizes="100vw"
                              objectFit="cover"
                              priority
                            />
                          ) : (
                            <Image
                              src={videoThumbnailFallback}
                              layout="fill"
                              objectFit="cover"
                            />
                          )
                        }
                        thumbnailMode="thumbnailOnly"
                        isActive={hoveredItemId === item._uid}
                      />
                    )
                  }
                  inverted={isBlockInverted}
                  visualType={item.video?.filename ? "video" : "image"}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </InteractiveGridUi>
  );
};

export default InteractiveGrid;
