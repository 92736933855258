import React, { ReactNode, useEffect, useState } from "react";
import cn from "classnames";
import s from "./ContentGrid.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import Item from "./components/Item";
import List from "./components/List";
import ListItem from "./components/ListItem";
import Container from "./components/Container";
import useIsInViewport from "use-is-in-viewport";
import {
  InnerSpacingVariant,
  WidthVariant,
} from "../../lib/storyblok/types/shared";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  title?: string;
  description?: string;
  twoColumns?: boolean;
  noGap?: boolean;
  spacing?: InnerSpacingVariant;
  isStartpage?: boolean;
  children: ReactNode;
  container?: WidthVariant;
  className?: string;
};

const subComponents = { Item, List, ListItem, Container };
type SubComponents = typeof subComponents;

const ContentGrid: CWS<Props, SubComponents> = ({
  title,
  description,
  twoColumns,
  noGap,
  spacing = "both",
  isStartpage,
  children,
  container = "xWide",
  className = "",
}) => {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false);

  useEffect(() => {
    if (isInViewport && !hasBeenInViewport) {
      setTimeout(() => {
        setHasBeenInViewport(true);
      }, 1000);
    }
  }, [isInViewport, hasBeenInViewport]);

  return (
    <section
      className={cn(s.section, bs[spacing], c[container], s[className], {
        [bs.onlyBottom]: spacing === "noTop",
        [bs.onlyTop]: spacing === "noBottom",
        [bs.none]: spacing === "noSpacing" || noGap,
        [bs.both]: spacing === "both" || spacing === undefined,
      })}
    >
      <div className={cn(c.xWide, s.intro)}>
        <BlockIntro
          alignment={"alignLeft"}
          inverted={false}
          title={title}
          description={description}
          buttons={""}
          width={"contained"}
        />
      </div>
      <div
        ref={targetRef}
        className={cn(s.contentGrid, c[container], {
          [s.twoColumns]: twoColumns,
          [s.noGap]: noGap,
          [s.marginTop]: title || description,
          [s.startpage]: isStartpage,
          [s.inViewport]: isInViewport || hasBeenInViewport,
        })}
      >
        {children}
      </div>
    </section>
  );
};

ContentGrid.Item = Item;
ContentGrid.List = List;
ContentGrid.ListItem = ListItem;
ContentGrid.Container = Container;

export default ContentGrid;
