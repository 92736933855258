import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import s from "../Authors.module.scss";
import { useTranslation } from "next-i18next";
import { ShareItem } from "../../Share";
import { ExpandButton } from "../../Speakers";

type Props = {
  image: ReactNode;
  name: string;
  rolesAndCompany: string;
  description: string;
  facebookLink?: string;
  linkedinLink?: string;
  twitterLink?: string;
  link?: string;
  showMoreLabel: string;
  showLessLabel: string;
};

const LINE_HEIGHT = 18;

const Author = ({
  image,
  name,
  rolesAndCompany,
  description,
  facebookLink,
  linkedinLink,
  twitterLink,
  link,
  showMoreLabel,
  showLessLabel,
}: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);

  const descRef = useRef<HTMLParagraphElement>(null);

  const setMaxHeight = useCallback(() => {
    const p = descRef.current;

    if (p) {
      p.style.minHeight = "0";
      p.style.maxHeight = LINE_HEIGHT * 5 + "px";

      if (showExpandButton) {
        p.style.minHeight = LINE_HEIGHT * 5 + "px";
      }

      if (p.scrollHeight > LINE_HEIGHT * 5) {
        setShowExpandButton(true);
      } else {
        setShowExpandButton(false);
      }
    }
  }, [setShowExpandButton, showExpandButton]);

  useEffect(() => {
    setMaxHeight();
    window.addEventListener("resize", setMaxHeight);
    return () => window.removeEventListener("resize", setMaxHeight);
  }, [setMaxHeight]);

  useEffect(() => {
    const p = descRef.current;

    if (p)
      if (isExpanded) {
        p.style.webkitLineClamp = "unset";
        p.style.maxHeight = `${p.scrollHeight}px`;
      } else {
        p.style.maxHeight = LINE_HEIGHT * 5 + "px";
      }
  }, [isExpanded]);

  return (
    <li className={s.author}>
      <div className={s.details}>
        {image && <div className={s.image}>{image}</div>}
        <div className={s.detailsWrapper}>
          <p className={s.name}>{name}</p>
          <p className={s.role}>{rolesAndCompany}</p>
          {(facebookLink || linkedinLink || twitterLink || link) && (
            <div className={s.share}>
              {link && (
                <ShareItem icon="link" href={link} title={t("website")} />
              )}
              {facebookLink && (
                <ShareItem
                  icon="facebook"
                  href={facebookLink}
                  title="Facebook"
                />
              )}
              {linkedinLink && (
                <ShareItem
                  icon="linkedin"
                  href={linkedinLink}
                  title="LinkedIn"
                />
              )}
              {twitterLink && (
                <ShareItem icon="twitter" href={twitterLink} title="X" />
              )}
            </div>
          )}
        </div>
      </div>
      <p ref={descRef} className={s.description}>
        {description}
      </p>
      {showExpandButton && (
        <ExpandButton
          text={isExpanded ? showLessLabel : showMoreLabel}
          isExpanded={isExpanded}
          toggleExpanded={() => setIsExpanded(!isExpanded)}
        />
      )}
    </li>
  );
};

export default Author;
