import React, { ReactNode, useRef } from "react";
import { useTranslation } from "next-i18next";
import { motion, useInView } from "framer-motion";
import cn from "classnames";
import RichText from "../../Common/RichText";
import s from "../InteractiveGrid.module.scss";
import Button from "../../Button";
import { StoryblokRichTextFieldtype } from "../../../lib/storyblok/types/fieldtypes/richText";
import BlockBackground from "../../Common/BlockBackground/BlockBackground";
import IconWithColor from "../../Common/IconWithColor";
import { useIsExternalLink } from "../../../hooks/useIsExternalLink";

interface ItemNonTouchProps {
  style?: string;
  icon?: string;
  title: string;
  description: StoryblokRichTextFieldtype;
  link?: string;
  image?: ReactNode;
  video?: ReactNode;
  rowIndex: number;
  columnIndex: number;
  isHovered: boolean;
  hasHoveredItemInRow: boolean;
  onHoverChange: (isHovered: boolean) => void;
  inverted?: boolean;
  visualType?: "image" | "video";
}

const ItemNonTouch: React.FC<ItemNonTouchProps> = ({
  style = "tobii-violet",
  icon,
  title,
  description,
  link,
  image,
  video,
  rowIndex,
  columnIndex,
  isHovered,
  hasHoveredItemInRow,
  onHoverChange,
  inverted,
  visualType = "video",
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const { isExternalLink } = useIsExternalLink(link);

  const timing = {
    defaultContent: {
      duration: isHovered ? 0.15 : 0.4,
      delay: isHovered ? 0 : 0.2,
      ease: isHovered ? "easeOut" : "easeInOut",
    },
    expandedContent: {
      duration: isHovered ? 0.3 : 0.1,
      delay: isHovered ? 0.6 : 0,
    },
    visualContainer: {
      duration: 0.7,
      ease: [0.4, 0, 0.2, 1],
      opacity: { duration: 0.3 },
    },
  };

  return (
    <motion.div
      ref={ref}
      className={cn(s.card, s.cardNonTouch, { [s.inverted]: inverted })}
      onHoverStart={() => onHoverChange(true)}
      onHoverEnd={() => onHoverChange(false)}
      data-is-hovered={isHovered}
      data-has-hovered-item-in-row={hasHoveredItemInRow && !isHovered}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: isInView ? 1 : 0,
        scale: isInView ? 1 : 0.8,
      }}
      transition={{
        duration: 0.3,
        delay: (rowIndex * 2 + columnIndex) * 0.05,
        ease: [0.2, 0, 0.1, 1],
      }}
    >
      <motion.div
        className={s.defaultContent}
        animate={{
          opacity: isHovered ? 0 : 1,
        }}
        transition={{
          duration: timing.defaultContent.duration,
          delay: timing.defaultContent.delay,
          ease: timing.defaultContent.ease,
        }}
      >
        <BlockBackground style={style} opacity={100} />
        <div className={s.contentContainer}>
          {icon && (
            <div className={s.icon}>
              <IconWithColor
                icon={icon}
                width={48}
                height={48}
                alt={title}
                color={inverted ? "white" : "black"}
              />
            </div>
          )}
          <motion.span
            className={s.title}
            animate={{
              opacity: hasHoveredItemInRow && !isHovered ? 0 : 1,
              height: hasHoveredItemInRow && !isHovered ? "0" : "auto",
            }}
            transition={{
              duration: 0.2,
            }}
          >
            {title}
          </motion.span>
        </div>
      </motion.div>

      {(video || image) && (
        <motion.div
          className={s.visualContainer}
          initial={false}
          animate={{
            opacity: isHovered ? 1 : 0.5,
            scale: isHovered ? 1.1 : 1,
          }}
          transition={{
            duration: timing.visualContainer.duration,
            ease: timing.visualContainer.ease,
            opacity: timing.visualContainer.opacity,
          }}
        >
          {visualType === "video" ? video : image}
        </motion.div>
      )}

      <motion.div
        className={s.expandedContent}
        initial={false}
        animate={{
          opacity: isHovered ? 1 : 0,
        }}
        transition={{
          duration: timing.expandedContent.duration,
          delay: timing.expandedContent.delay,
        }}
      >
        <motion.div
          className={s.contentContainer}
          animate={{
            opacity: isHovered ? 1 : 0,
            y: isHovered ? 0 : 20,
          }}
          transition={{
            duration: timing.expandedContent.duration,
            delay: timing.expandedContent.delay,
          }}
        >
          <span className={s.title}>{title}</span>
          {description && (
            <div className={s.description}>
              <RichText content={description} alignment="alignLeft" inverted />
            </div>
          )}
          {link && link !== "/" && link.trim() !== "" && (
            <Button
              variant="inverted"
              size="medium"
              href={link}
              title={title}
              icon={!isExternalLink ? "arrow-right" : undefined}
            >
              {t("learn-more")}
            </Button>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ItemNonTouch;
