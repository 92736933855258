import Link from "next/link";
import React from "react";
import { TextAndImageBlock } from "../../lib/storyblok/types/blocks/TextAndImageBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import Button from "../../ui/Button";
import MediaAndImageUi from "../../ui/MediaAndImage";
import ImageVaultImage from "../ImageVaultImage";
import { useContentStyle, useButtonStyle } from "../../hooks/useStyle";

const TextAndImage: StoryblokBlockComponent<TextAndImageBlock> = ({
  text,
  title,
  image,
  reversed,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
  typeformCopy,
  typeformID,
  typeformSize,
  typeformButtonStyle,
  outerSpacing,
  imageKeepProportions,
  layout,
  className,
  overlayVisible,
}) => {
  const resolveLink = useLinks();
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;
  const { buttonVariant } = useButtonStyle(style);

  return (
    <MediaAndImageUi
      className={className}
      overlayVisible={overlayVisible}
      style={style === "white" || style === "gray" ? "none" : style}
      outerSpacing={outerSpacing}
      imageKeepProportions={imageKeepProportions}
      layout={layout}
      reversed={reversed}
      title={title}
      preamble={text}
      image={
        isValidImageData(image) ? (
          <ImageVaultImage
            sizes={imageSizes("100vw", { desktop: "50vw" })}
            image={image}
            layout="fill"
          />
        ) : null
      }
      button={
        buttonText && (
          <Button href={resolveLink(buttonLink)} variant={buttonVariant}>
            {buttonText}
          </Button>
        )
      }
      secondButton={
        secondButtonText && (
          <Link passHref href={resolveLink(secondButtonLink)} prefetch={false}>
            <Button
              href={resolveLink(secondButtonLink)}
              variant={buttonVariant}
            >
              {secondButtonText}
            </Button>
          </Link>
        )
      }
      typeformCopy={typeformCopy}
      typeformID={typeformID}
      typeformSize={typeformSize}
      typeformButtonStyle={typeformButtonStyle}
      inverted={isInverted}
      mediaBackground={"mediaBgWhite"}
    />
  );
};

export default TextAndImage;
